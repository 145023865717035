.centered-container {
  width: 700px; /* Fixed width */
  height: 550px; /* Fixed height */
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between; /* Keep space between content and image */
  position: relative;
  overflow: hidden;

}

.contentDiv {
  width: 40%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; /* Align items to center for buttons */
  height: 100%;

}




.imageDiv12 {
  width: 60%; /* Adjusted width to 60% for the image */
  height: 100%; /* Ensure the div covers the full height */
  display: flex; /* Ensure the image takes full height */

}

img#cimage2 {
  width: 100%; /* Ensure the image takes up the full width */
  height: 100%; /* Ensure the image takes up the full height */
  object-fit: cover; /* Ensure the image covers the area without distorting */
}



  .main-heading {
    font-size: 26px;
    font-weight: bold;
    line-height: 1.4;
    max-height: 1000px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4; /* Limit to 4 lines */
    -webkit-box-orient: vertical;
    white-space: normal;
    margin: 0%;
  }

  .sub-heading {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.4;
    max-height: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
  }

  .description-text {
    font-size: 16px;
    line-height: 1.6;
    max-height: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;

  }

/* Styles for the new button */
.body-btn {
  width: 200px; /* Keep the same width */
  height: 40px;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  text-align: center; /* Center text */
  background-color: #000000; /* Different background color (use any color of your choice) */
  color: white; /* Text color */
  border: none; /* No border */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Add smooth transition for hover effects */
  margin-bottom: 10px; /* Add margin to separate buttons */
}

/* Hover effect */
/* .body-btn:hover {
  background-color: #e89d96; 
  transform: translateY(-2px);
} */
.body-btn-outline {
  width: 200px; /* Keep the same width */
  height: 40px;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  text-align: center; /* Center text */
  color: black; /* Set text color to black */
  background-color: transparent; /* Different background color (use any color of your choice) */
  /* color: white; */
  border: 1px solid black;
  
  transition: background-color 0.3s ease, transform 0.2s ease; /* Add smooth transition for hover effects */
  margin-bottom: 20px; /* Add margin to separate buttons */
}

  .footer-section {
    position: absolute; /* Absolute positioning to lock it */
    bottom: 0; /* Lock to bottom */
     /* Ensure it spans full width */
    height: 10px; /* Adjust the height */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    
  }
  
  /* Footer alignment */
  .footer-link {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
  }
  
  .powered-by {
    font-size: 10px;
    margin-right: 5px;
    margin-left: 5px;
  }
  
  .footer-logo {
    width: 40px;
  
  }

/* Media Query for Mobile */
@media only screen and (max-width: 768px) {
  .centered-container {
    width: 390px;
    height: 475px;
    flex-direction: column-reverse; /* Reverse the order of elements */
    text-align: center;
    padding: 0;
    position: relative;

  }

  .contentDiv {
    width: 100%;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    margin: 0;
    justify-content: space-between;
    
  }

  /* Hide the sub-heading in mobile view */
  .sub-heading {
    display: none;
  }

  /* Limit the main heading to 20 characters */
  .main-heading {
    font-size: 24px;
    font-weight: bold;
    line-height: 1.2;
    max-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Limit to 1 line */
    -webkit-box-orient: vertical;
    white-space: nowrap;
    margin: 0;

  }

  /* Limit the description text to 40 characters */
  .description-text {
    font-size: 14px;
    line-height: 1.4;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    white-space: normal;

    margin: 0;
  }

  .imageDiv11 {
    width: 400px; /* Ensure the div takes full width */
    height: 100%; /* Set height to 50% of the viewport height (or adjust as needed) */
    object-fit: cover; /* Make sure it stays relative to parent container */
    overflow: hidden; /* Hide any overflow to ensure the image stays inside */
  }
  
  img#cimage2 {
    width: 100%; /* Make the image take up the full width */
    height: 100%; /* Make the image fill the container height */
    object-fit: cover; /* Cover the container without distorting the image */
  }
  
/* Styles for the new button */
.body-btn {
  width: 300px; /* Keep the same width */
  height: 30px;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  text-align: center; /* Center text */
  background-color: #000000; /* Different background color (use any color of your choice) */
  color: white; /* Text color */
  border: none; /* No border */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Add smooth transition for hover effects */
  margin-bottom: 0px; /* Add margin to separate buttons */
}

/* Hover effect */
/* .body-btn:hover {
  background-color: #e89d96; 
  transform: translateY(-2px);
} */
.body-btn-outline {
  width: 300px; /* Keep the same width */
  height: 30px;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  text-align: center; /* Center text */
  color: black; /* Set text color to black */
  background-color: transparent; /* Different background color (use any color of your choice) */
  /* color: white; */
  border: 1px solid black;
  
  transition: background-color 0.3s ease, transform 0.2s ease; /* Add smooth transition for hover effects */
  margin-bottom: 10px; /* Add margin to separate buttons */
}



}


@media only screen and (max-width: 375px) {
  .centered-container {
    width: 350px;
    height: 455px;
    flex-direction: column-reverse; /* Reverse the order of elements */
    text-align: center;
    padding: 0;
    position: relative;

  }

  .contentDiv {
    width: 100%;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    margin: 0;
    justify-content: space-between;
  }

  img#cimage2 {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  /* Hide the sub-heading in mobile view */
  .sub-heading {
    display: none;
  }

  /* Limit the main heading to 20 characters */
  .main-heading {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Limit to 1 line */
    -webkit-box-orient: vertical;
    white-space: normal;
    margin: 0;

  }

  /* Limit the description text to 40 characters */
  .description-text {
    font-size: 13px;
    line-height: 1.4;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    white-space: normal;

    margin: 0;
  }

  .body-btn {
    width: 270px; /* Keep the same width */
    height: 30px;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    text-align: center; /* Center text */
    color: white; /* Text color */
    border: none; /* No border */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Add smooth transition for hover effects */
    margin-bottom: 0px; /* Add margin to separate buttons */
  }
  
  /* Hover effect */
  /* .body-btn:hover {
    background-color: #e89d96; 
    transform: translateY(-2px);
  } */
  .body-btn-outline {
    width: 270px; /* Keep the same width */
    height: 30px;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    text-align: center; /* Center text */
    
    background-color: transparent; /* Different background color (use any color of your choice) */
    /* color: white; */
    border: 1px solid black;
    
    transition: background-color 0.3s ease, transform 0.2s ease; /* Add smooth transition for hover effects */
    margin-bottom: 10px; /* Add margin to separate buttons */
  }


}


@media only screen and (max-width: 320px) {
  .centered-container {
    width: 310px;
    height: 425px;
    flex-direction: column-reverse; /* Reverse the order of elements */
    text-align: center;
    padding: 0;
    position: relative;

  }

  .contentDiv {
    width: 100%;
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    margin: 0;
    justify-content: space-between;
  }

  img#cimage2 {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  
  /* Hide the sub-heading in mobile view */
  .sub-heading {
    display: none;
  }

  /* Limit the main heading to 20 characters */
  .main-heading {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* Limit to 1 line */
    -webkit-box-orient: vertical;
    white-space: nowrap;
    margin: 0;

  }

  /* Limit the description text to 40 characters */
  .description-text {
    font-size: 12px;
    line-height: 1.4;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    white-space: normal;

    margin-top: 10px;
    margin-bottom: 10px;
  }

  .body-btn {
    width: 230px; /* Keep the same width */
    height: 30px;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    text-align: center; /* Center text */
    background-color: #000000; /* Different background color (use any color of your choice) */
    color: white; /* Text color */
    border: 1px solid black;
    transition: background-color 0.3s ease, transform 0.2s ease; /* Add smooth transition for hover effects */
    margin-bottom: 0px; /* Add margin to separate buttons */
  }
  
  /* Hover effect */
  /* .body-btn:hover {
    background-color: #e89d96; 
    transform: translateY(-2px);
  } */
  .body-btn-outline {
    width: 230px; /* Keep the same width */
    height: 30px;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    text-align: center; /* Center text */
    color: black; /* Set text color to black */
    background-color: transparent; /* Different background color (use any color of your choice) */

    
    transition: background-color 0.3s ease, transform 0.2s ease; /* Add smooth transition for hover effects */
    margin-bottom: 10px; /* Add margin to separate buttons */
  }

}
