/* Default styles */
body {
    font-family: 'Asap', sans-serif;
  }
  
  .fh1 {
    text-align: center;
    font-weight: 600;
    font-size: 20px;
    margin-top: 40px;
    font-family: 'Asap', sans-serif;
    position: absolute;
  }
  
  .fimg {
    margin-top: 160px;
    margin-left: -300px;
    position: absolute;
  }
  
  .fimg1 {
    margin-top: 170px;
    margin-left: 300px;
    position: absolute;
  }
  
  .fh2 {
    text-align: center;
    font-size: 15px;
    margin-top: 320px;
    font-family: 'Asap', sans-serif;
    position: absolute;
    margin-left: 300px;
    font-weight: 400;
  }
  
  .fimg2 {
    margin-top: 460px;
    margin-left: 1px;
    position: absolute;
  }
  
  /* Responsive styles for smaller screens */

  @media (max-width: 375px)  { 
    .fh1 {
      font-size: 6px;
      margin-top: 30px;
      margin-right: 12%;
      color: rgb(0, 0, 0);
    }
  
    .fimg {
      margin-top: 120px;
      margin-right: 12%;
      width: 230px;
    }
  
    .fimg1 {
      margin-top: 440px;
      margin-right: 18%;
      width: 150px;
    }
  
    .fh2 {
      font-size: 12px;
      margin-top: 390px;
      margin-right: 12%;
    }
  
    .fimg2 {
      margin-top: 610px;
      margin-right: 14%;
    }
   }

  
  @media (max-width: 768px)  {
    .fh1 {
      font-size: 16px;
      margin-top: 30px;
      margin-left: -105px;
    }
  
    .fimg {
      margin-top: 120px;
      margin-left: -110px;
      width: 230px;
    }
  
    .fimg1 {
      margin-top: 440px;
      margin-left: -100px;
      width: 150px;
    }
  
    .fh2 {
      font-size: 12px;
      margin-top: 390px;
      margin-left: -115px;
    }
  
    .fimg2 {
      margin-top: 610px;
      margin-left: -110px;
    }
  }
  