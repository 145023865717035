/* Video Carousel Container */
.video-carousel {
    width: 100%;
    height: 100vh;
    max-width: 1024px;
    margin: 0 auto;
    padding: 0px;
    background-color: transparent;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center the Slider vertically */
    
}


/* Video Slide */
.video-slide {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 90vh;
    padding-bottom: 5%; /* Space for the product card */
}

/* Active Slide */
.slick-slide.slick-active.slick-center.slick-current {
    transform: scale(1.2) !important;
    z-index: 1 !important;
    opacity: 1.0 !important;
}


/* Inactive Slide */
.slick-slide.slick-active:not(.slick-current) {
    z-index: -1 !important;
    opacity: 0.8;
    transform: scale(1.0) !important;

}

/* General Slide Styling */
.slick-slide {
    position: relative; /* Ensure slides are positioned */
}

/* Video Title Container */
.video-title-container {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 75%;
    padding: 0 10px;
    font-size: 1rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    color: white;
    position: absolute;
    top: 2%;
    left: 0%;
    z-index: 10;
}

/* Video Icons */
.video-icons {
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.icon {
    cursor: pointer;
    font-size: 0.9rem;
    color: white;
}

.icon:hover {
    opacity: 0.8;
}

/* Video Container */
.video-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    
}

/* Video Wrapper */
.video-wrapper {
    width: 100%;
    max-width: 475px;
    height: auto;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
}

/* Carousel Video */
.carousel-video {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 15px;
    cursor: pointer;
    aspect-ratio: 9 / 16;
}

/* Product Card Carousel */
.product-card-carousel {
    width: 60%;
    max-width: 475px;
    background: rgba(255, 0, 0, 0);
    padding: 0px;
    border-radius: 10px;
    position: absolute;
    bottom: 5%;
}

/* Product Card */
.product-card {
    background: rgba(227, 130, 130, 0);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0px;
    border-radius: 8px;
    width: 100%;
    max-width: 300px;
    gap: 0px;
}

/* Product Card Content */
.product-card-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 300px;
    background: #444; /* Dark gray background */
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

/* Product Header */
.product-header {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 10px;
    background: #444;
    color: white;
    font-weight: bold;
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: flex-start;
}

/* Scrollable Content */
.scrollable-content {
    background: rgb(255, 255, 255);
    max-height: 600px;
    overflow-y: scroll;
    padding: 10px;
    flex-grow: 1;
}

/* Hide Scrollbar */
.scrollable-content::-webkit-scrollbar {
    display: none; /* Hides the scrollbar */
}

/* Product Image */
.product-image {
    width: 16%;
    height: 16%;
    object-fit: cover;
    border-radius: 5px;
    margin-right: 8px;
}

/* Product Info */
.product-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    text-align: left;
}

/* Product Title */
.product-title {
    font-size: 0.8rem;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

/* Product Price */
.product-price {
    font-size: 0.8rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 600;
    color: #e0b066; /* Gold price color */
    text-align: left;
    margin: 0;
}

/* Product Actions */
.product-actions {
    display: flex;
    align-items: center;
}

/* Quantity Number */
.quantity-number {
    font-size: 1rem;
    font-weight: bold;
    color: white;
}

/* Checkout Button Container */
.checkout-btn-container {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

/* Checkout Button */
.checkout-btn {
    width: 100%;
    padding: 5px 10px;
    background: #ffc107;
    border: none;
    border-radius: 5px;
    color: black;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
}

/* Checkout Button Hover */
.checkout-btn:hover {
    background-color: #f0cc17;
}

/* Modal */
.product-modal {
    background: rgb(255, 255, 255);
    border-radius: 30px;
}

/* Modal Full Overlay */
.product-modal.full-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    
}

.product-modal .blur-overlay {
    position: fixed;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 97%;
    height: 97%;
    background: rgb(0, 0, 0); /* Semi-transparent background */
    z-index: -1; /* Ensure it's below the modal content */
    border-radius: 50px;
}

/* Modal Content */
.modal-content {
    background: rgb(255, 255, 255);
    padding: 20px;
    /* border-radius: 30px; */
    /* max-width: 100%; */
    width: 100%;
    /* max-height: 90%; */
    overflow-y: auto;
    text-align: center;
    position: relative;
    height: 500px;
}

/* .product-modal.open ~ .slick-slider .slick-arrow {
    display: none !important;
} */

.modal-open .slick-prev, 
.modal-open .slick-next {
    display: none !important;
}
/* Close Button */
.close-button {
    position: absolute;
    background-color: black;
    color: white;
    border: none;
    border-radius: 100%;
    font-size: 1.5rem;
    width: 25px;
    height: 25px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 10px;
    right: 10px;
    padding: 0;
}

/* Close Button Hover */
.close-button:hover {
    background-color: #333;
}

/* Modal Product Image */
.modal-product-image {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 10px;
}

/* Modal Product Title */
.modal-product-title {
    font-size: 1.0rem;
    font-weight: bold;
    color: black;
    margin-bottom: 0px;
    text-align: left;
}

/* Modal Product Price */
.modal-product-price {
    font-size: 0.8rem;
    font-weight: 600;
    color: #ff6600;
    margin-bottom: 10px;
    text-align: left;
}

/* Modal Product Description Heading */
.modal-product-description-heading {
    font-size: 1.0rem;
    font-weight: normal;
    color: black;
    margin-bottom: 10px;
    text-align: left;
}

.modal-product-description{
    font-size: 0.75rem;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    font-weight: 300;
    line-height: 1.5;
    color: #333;
    margin-bottom: 15px;
    text-align: left;
}


/* Shop Now Button */
.shop-now-modal-button {
    width: 100%;
    background: #e0b066;
    color: #333;
    font-size: 0.8rem;
    font-weight: normal;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
}

.shop-now-modal-button:hover {
    background-color: #e0a800;
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.shop-now-modal-button:active {
    background-color: #d29e00;
    transform: scale(0.98);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
}

    /* Hide the fullscreen button */
video::-webkit-media-controls-fullscreen-button {
    display: none !important;
}

/* Hide the download button */
video::-webkit-media-controls-enclosure {
    overflow: hidden;    
}

/* video::-webkit-media-controls-panel {
    display: flex !important;
    visibility: visible !important;
} */

/* Hide Play/Pause button */
video::-webkit-media-controls-play-button {
    display: none !important;

}

/* Hide video duration (timestamp) */
video::-webkit-media-controls-current-time-display,
video::-webkit-media-controls-time-remaining-display {
    display: none !important;
}

/* Hide the three-dot menu (More Options) */
video::-webkit-media-controls-panel {
    overflow: hidden !important;
}

/* Adjust the control panel so it's not pushed up */
video::-webkit-media-controls-panel {
    margin-bottom: 560px; /* Moves the panel up if necessary */
    background: none !important;
}

/* Optional: Adjust height of controls */
video::-webkit-media-controls-timeline {
    display: none !important;
}

/* video::-webkit-media-controls-mute-button:hover {
    display: none !important;
} */

.cart-actions {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    
}

.quantity-btn {
    background: black;
    /* background-color: none; */
    border: none;
    font-size: 15px;
    cursor: pointer;
    color: white;
}

.quantity-btn:hover {
    color: #ffdf00;
}

.cart-quantity {
    font-weight: bold;
    font-size: 15px;
}

.video-top-blur {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 7%; /* Apply the effect to the top 10% of the video */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); 
    backdrop-filter: blur(1.5px); /* Apply the blur effect */
    z-index: 0; /* Ensure it's above the video */
    pointer-events: none; /* Prevent interaction */
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

/* Variant Selector */
.variant-selector {
    margin: 10px 0;
    text-align: left;
}

.variant-selector h3 {
    font-size: 0.9rem;
    font-weight: bold;
    color: black;
    margin-bottom: 5px;
    margin-top: 5px;
}

.variant-selector select {
    width: 100%;
    padding: 2px;
    font-size: 11px;
    border-radius: 5px;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: white;
    
}

/* When the user hovers over the dropdown */
.variant-selector select:hover {
    border-color: #999;
}

/* When the dropdown is focused */
.variant-selector select:focus {
    border-color: #e0b066; /* Gold accent */
    outline: none;
}

/* Disabled option */
.variant-selector option:disabled {
    color: #999;
}

/* Hide scrollbar inside the select dropdown on webkit browsers */
.variant-selector select::-webkit-scrollbar {
    display: none;
}

@media (min-width: 1024px) {
    .custom-prev-arrow,
    .custom-next-arrow {
        display: none !important;
    }
}

/* Display custom arrows on mobile (up to 1024px) */
@media (max-width: 1024px) {
    .custom-prev-arrow,
    .custom-next-arrow {
        display: flex;
    }
}


/* Mobile View: For screens 768px and below */
@media (max-width: 768px) {
    .video-carousel {
        width: 100vw;
        height: 100vh;
        /* max-width: 1024px; */
        margin: 0px;
        padding: 0px;
        background-color: transparent;
        /* position: relative; */
        /* display: flex;
        flex-direction: column;*/
        justify-content: center;  
        /* top:0; */
        /* left:0; */
        background: rgba(0, 0, 0, 0.3); 
        backdrop-filter: blur(15px); 
        -webkit-backdrop-filter: blur(15px); 
    }

    .carousel-video {
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        border-radius: 15px;
        cursor: pointer;
    }

    .video-top-blur {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 7%; /* Apply the effect to the top 10% of the video */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); 
        backdrop-filter: blur(1.5px); /* Apply the blur effect */
        z-index: 0; /* Ensure it's above the video */
        pointer-events: none; /* Prevent interaction */
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }
    

    .video-wrapper {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden; /* Prevents unwanted scrolling */
    }


    video::-webkit-media-controls-panel {
        margin-bottom: 830px; /* Moves the panel up if necessary */
        background: none !important;
    }

/* Custom Arrow Styling */
.custom-prev-arrow,
.custom-next-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px; /* Reduce size */
    color: white;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    width: 30px; /* Reduce width */
    height: 30px; /* Reduce height */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    transition: all 0.3s ease-in-out;
}

.custom-prev-arrow {
    left: 10px; /* Adjust positioning */
}

.custom-next-arrow {
    right: 10px; /* Adjust positioning */
}

/* Hover effect */
.custom-prev-arrow:hover,
.custom-next-arrow:hover {
    background: rgba(255, 255, 255, 0.7);
    color: black;
}


    
}
