*body {
  font-family: 'Asap', sans-serif;
}

.he1 {
  font-family: 'Asap', sans-serif;
  font-weight: 700px;
  font-size: 20px;
  width: 500px;
  margin-left: -255px;
  margin-top: 35px;
  position: absolute;

}

.he2 {
  font-size: 16px;
  margin-top: 90px;
  margin-left: -270px;
  font-family: 'Asap', sans-serif;
  font-weight: 500;
  position: absolute;
}

.he3 {
  font-size: 16px;
  margin-top: 90px;
  margin-left: 410px;
  position: absolute;
  font-family: 'Asap', sans-serif;
  font-weight: 500;
}

.star-rating {
  position: absolute;
  margin-top: 120px;
  margin-left: -270px;
}


.star-rating2 {
  position: absolute;
  margin-top: 120px;
  margin-left: 412px;
}

.t1 {
  font-family: 'Asap', sans-serif;
  width: 555px;
  height: 200px;
  background-color: rgb(214, 205, 205);
  border: none;
  margin-left: -5px;
  margin-top: 180px;
  padding-left: 10px;
  padding-top: 10px;
  font-size: 14px;
  position: absolute;


}

.imgicon15 {
  margin-top: 410px;
  margin-left: -147px;
  position: absolute;
  width: 130px;

}

.he4 {
  font-weight: 600;
  font-size: 15px;
  margin-left: -108px;
  margin-top: 416px;
  position: absolute;
  color: rgb(255, 255, 255);
  font-family: 'Asap', sans-serif;

}

.imgicon16 {
  margin-top: 410px;
  margin-left: 20px;
  position: absolute;
  width: 130px;
}

.he5 {
  font-weight: 700;
  font-size: 15px;
  margin-left: 70px;
  margin-top: 416px;
  position: absolute;
  color: rgb(0, 0, 0);
  font-family: 'Asap', sans-serif;


}

.logolast1 {
  position: absolute;
  margin-top: 465px;
  margin-left: 10px;
}


/* Media query for screens with a maximum width of 768 pixels (typical for mobile devices) */
@media (max-width: 768px) {
  
  .he1 {
    font-size: 16px;
    margin-top: 30px;
    margin-left: -308px;
    position: absolute;
  }

  
  .he2{
      font-size: 12px;
      margin-top: 80px;
      margin-left: -110px;
    }


  .he3 {
    font-size: 12px;
    margin-top: 160px;
    margin-left: -100px;
  }


  .star-rating{
      margin-top: 103px;
      margin-left: -105px;
    }

  .star-rating2 {
    margin-top: 180px;
    margin-left: -105px;
  }


  .t1 {
    margin-left: -100px;
    margin-top: 250px;   
      width: 340px;
    font-size: 12px;
  }

 
  .imgicon15 {
      margin-top: 485px;
      margin-left: -100px;
      width: 100px;
      position: absolute;
    }

  .imgicon16 {
    margin-top:530px;
    margin-left: -100px;
    width: 100px;
  }

 
  .he4 {
      font-size: 12px;
      margin-left: -70px;
      margin-top: 490px;
      
          }

  .he5 {
    font-size: 12px;
    margin-left: -60px;
    margin-top: 534px;
  }


  .logolast1 {
    margin-top: 620px;
    margin-left: -120px;
  }
}