
:root{
    --headerHeading:14px;
    --headerParagraph:12px;
    --btnfontsize:12px;
    --GradientColor1:cornflowerblue;
    --GradientColor2:rgb(233, 126, 144);
    --GradientColor3:rgb(229, 173, 6);
    --buttonpadding:3px 0px;
}



*body {
    font-family: Arial, Helvetica, sans-serif, !important;
  }
  
  
  .header3 {
    text-align: center;
    font-weight: 600;
    font-size: var(--headerHeading);
    font-family: 'Asap', sans-serif;
    
  
  }
  
  .header4 {
    text-align: center;
    font-weight: 600;
    font-size: var(--headerHeading);
    font-family: 'Asap', sans-serif;
    margin-top: -0.50%;
    
    
  }
  
  /* #cimage1 {
    margin-top: 70%;
    margin-left: -30%;
    width: 2000rem;
    max-width: 100%;
  } */
  
  
  .pp1 {
    
    color: black;
    font-weight: 700;
    font-size: var(--headerParagraph);
    width: 205px;
    
  }
  .pimg{
    display: block;
  }

  .pp1 span{
    display: block;
    padding-top: 7px;
  }
  .crossbtnDiv{
    display: flex;
    justify-content: end;
  }
  .crossbtn{  
    border: none;
    width: 30px;
    padding: var(--buttonpadding);
    font-size: var(--btnfontsize);
    background: transparent;
    padding-top: 4% ;
    margin-right: 4% ;
  }
  .bb1 {
       
    font-size: var(--btnfontsize);
    /* background: linear-gradient(45deg, var(--GradientColor1), var(--GradientColor2), var(--GradientColor3)); */
    background: #000;
    color: white;
    font-weight: 400;
    border-radius: 50px;
    border: none;
    width: 180px;
    padding: var(--buttonpadding);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .bb1Outline{
    font-size: var(--btnfontsize);
    /* background: linear-gradient(45deg, var(--GradientColor1), var(--GradientColor2), var(--GradientColor3)); */
    background: transparent;
    border: 1.4px solid #000;
    color: #000;
    font-weight: 600;
    border-radius: 50px;
    width: 180px;
    padding: var(--buttonpadding);
  }
  
  .bb2 {
     /* margin-left: -1%; */
     margin-top: 5%;
     /* width: 81%; */
     /* height: 8%; */
     font-size: 70%;
     background: linear-gradient(45deg, cornflowerblue, rgb(233, 126, 144), rgb(229, 173, 6));
     font-family: Asap, sans-serif;
     /* border: rgb(255, 255, 255) solid; */
     color: rgb(255, 255, 255);
     font-weight: 600;
     border-radius: 50px;
     border: none;
  }
  
  
  /* Styles for the loader */
  .loader {
    border: 4px solid #f3f3f3;
    /* Light grey */
    border-top: 4px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -25px;
    /* Half of the height */
    margin-left: -25px;
    /* Half of the width */
    z-index: 9999;
    /* Ensure it appears on top of everything */
    animation: spin 1s linear infinite;
    /* Rotate the loader itself */
  }
  
  /* Styles for the blue circle moving around the loader */
  
  
  /* Keyframe animation for spinning the loader */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
  
    /* Start at 0 degrees */
    100% {
      transform: rotate(360deg);
    }
  
    /* End at 360 degrees (full circle) */
  }
  
  /* Keyframe animation for moving the blue circle around the loader */
  @keyframes moveAround {
    0% {
      transform: translate(-50%, -50%) rotate(0deg) translateX(30px);
    }
  
    /* Start at 0 degrees */
    100% {
      transform: translate(-50%, -50%) rotate(360deg) translateX(30px);
    }
  
    /* End at 360 degrees (full circle) */
  }
  
  /* Styles for the transparent overlay */
  .overlay {
    position: fixed;
    /* Full screen */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    z-index: 9998;
    /* Place it below the loader */
  }
  

    
  #row1{
    background-color: aqua;
  }

  

  
  .closebtn1 {
   
   cursor: pointer;
  }
  
  /* 
  
  @media (max-width: 768px) {
  
    #cimage1 {
    margin-top: 10%;
    margin-left: 14%;
    width: 15rem;
    
   
  }
  
  .header3 {
    text-align: center;
    font-weight: 600;
    font-size: 100%;
    font-family: 'Asap', sans-serif;
    margin-left: 1%;
    margin-top:5%;
  
  }
  
  .header4 {
    text-align: center;
    font-weight: 600;
    font-size: 100%;
    font-family: 'Asap', sans-serif;
    margin-top: -1%;
    margin-left: 1%px;
    
  }
  
  .pp1 {
    margin-left: 24%;
    margin-top: 18%;
    color: black;
    font-weight: 500;
    font-family: Asap, sans-serif;
    font-size: 100%;
  }
  
  
  .bb1 {
    margin-left:24%;
    margin-top: 1%;
    width:52%;
    height: 13%;
    font-size: 70%;
    background: linear-gradient(45deg, cornflowerblue, rgb(233, 126, 144), rgb(229, 173, 6));
    font-family: Asap, sans-serif;
    border: white solid;
    color: white;
    font-weight: 600;
    border-radius: 50px;
  }
  
  .bb2 {
    margin-left: 24%;
    margin-top: 1%;
    width:52%;
    height: 12%;
    font-size: 70%;
    background: linear-gradient(45deg, cornflowerblue, rgb(233, 126, 144), rgb(229, 173, 6));
    font-family: Asap, sans-serif;
    border: rgb(255, 255, 255) solid;
    color: rgb(255, 255, 255);
    font-weight: 600;
    border-radius: 50px;
  }
  
  #footer {
    margin-top: 15%;
   
  
  }
  
  } */



  .modal-content {
    /* height: 500px; */
    border-radius: 5px !important;
    /* background-color:transparent !important; */
  }
  .modal-dialog{
    max-width:fit-content !important;
    /* background-color:pink; */
  }

.fullheight{
    height: 100%;
}

.contentDiv button:last-child{
margin-top: 5%;
}

.imageDiv img{
    max-width: 180px !important;
}

.bodyContainer{
    width: 500px !important;
    height: 500px;
}

@media screen and (max-width:540px){
.bodyContainer{
    width: 100% !important;
}
.V-navbar img{
  width: 40px;
}

/* .footer{
  margin-top: 50%;
} */
}
@media screen and (max-width:470px) {

    .modal{
        display: flex !important;
        justify-content: center !important;
    }
    .pp1{
        width: 180px;
    }
    .modal-dialog{
        /* max-width: 100% !important;  */
        width: calc(100% - 10px) !important; 
      }
    :root{
        --headerHeading:14px;
        --headerParagraph:12px;
    }
    .contentDiv{
        align-items: center;
    }
    .bodyContainer .bodyContainer2ndDiv .w-full{
        width: 100% !important;
    }
    .bodyContainer .row:nth-child(2){
        align-items: center;
        flex-direction: column ;
    }
    .bodyContainer .row:nth-child(2) div:last-child{
        padding-top: 25px;
    }

    /* .footer{
      padding-top: 20%;
    } */

    
}
.footer{
  width: 22%;
}
.marginRL{
  margin-right:0px !important;
  margin-left:0px !important;
}
.micimg{
  width: 8px;
  margin-right: 5px !important;
}
.crossbtnsvg{
  width: 40%;
}
.margin20{
  height: 15%;
}