.centered-container2 {
  width: 700px;
  height: 550px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.contentDiv2 {
  width: 40%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}




.imageDiv12 {
  width: 60%;
  height: 100%;
  display: flex;


}

img#cimage1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.main-heading2 {
  font-size: 26px;
  font-weight: bold;
  line-height: 1.4;
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  white-space: normal;
  margin: 0%;
}

.sub-heading2 {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.4;
  max-height: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.description-text2 {
  font-size: 16px;
  line-height: 1.6;
  max-height: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  white-space: normal;
  color: #666666;
  margin-bottom: 70px;
}

.footer-section2 {
  position: absolute;
  bottom: 0;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

}

/* Footer alignment */
.footer-link2 {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: black;
}

.powered-by2 {
  font-size: 10px;
  margin-right: 5px;
  margin-left: 5px;
}

.footer-logo2 {
  width: 35px;

}

img#cimage1 {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Styles for the new button */
.perm-btn {
  width: 200px;
  height: 40px;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  background-color: #000000;
  color: white;
  border: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-bottom: 10px;
}

/* Hover effect */
/* .body-btn:hover {
  background-color: #e89d96; 
  transform: translateY(-2px);
} */
.perm-btn-outline {
  width: 200px;
  height: 40px;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  color: black; 
  background-color: transparent;
  border: 1px solid black;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {

  .centered-container2 {
    width: 390px;
    height: 475px;
    flex-direction: column-reverse;
    text-align: center;
    position: relative;

  }

  .contentDiv2 {
    width: 100%;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    margin: 0;
    justify-content: space-between
  }



  /* Hide the sub-heading in mobile view */
  .sub-heading2 {
    display: none;
  }

  /* Main heading with text truncation */
  .main-heading2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    max-height: 1000px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Limit to 1 line */
    -webkit-box-orient: vertical;
    white-space: normal;
    margin: 0;

  }

  /* Description text with text truncation */
  .description-text2 {
    font-size: 13px;
    line-height: 1.5;
    max-height: 1000px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    white-space: normal;

    margin: 0;

  }


  .footer-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
  }

  .powered-by {
    font-size: 10px;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 5px;
    margin-left: 5px;
  }

  .footer-logo {
    width: 40px;
    margin-bottom: 10px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
  }

  .imageDiv13 {
    width: 100%;
    /* Ensure the div takes full width */
    height: 50vh;
    /* Set height to 50% of the viewport height */
    position: relative;
    /* Ensure the container stays relative */
    overflow: hidden;
    /* Hide any overflow */
  }

  img#cimage1 {
    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    object-fit: cover;
    /* Ensure the image covers the entire container */
    object-position: center;
    /* Center the image */
  }

  .perm-btn {
    width: 300px;
    height: 30px;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    background-color: #000000;
    color: white;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-bottom: 0px;
  }

  /* Hover effect */
  /* .body-btn:hover {
      background-color: #e89d96; 
      transform: translateY(-2px);
    } */
  .perm-btn-outline {
    width: 300px;
    /* Keep the same width */
    height: 30px;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    /* Center text */

    background-color: transparent;
    /* Different background color (use any color of your choice) */
    /* color: white; */
    border: 1px solid black;
    color: black; /* Set text color to black */
    transition: background-color 0.3s ease, transform 0.2s ease;
    /* Add smooth transition for hover effects */
    margin-bottom: 10px;
    /* Add margin to separate buttons */
  }

}

@media only screen and (max-width: 375px) {
  .centered-container2 {
    width: 350px;
    height: 455px;
    flex-direction: column-reverse;
    /* Reverse the order of elements */
    text-align: center;
    padding: 0;
    position: relative;

  }

  .contentDiv2 {
    width: 100%;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    margin: 0;
    justify-content: space-between;
  }


  /* Hide the sub-heading in mobile view */
  .sub-heading {
    display: none;
  }

  /* Limit the main heading to 20 characters */
  .main-heading2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Limit to 1 line */
    -webkit-box-orient: vertical;
    white-space: normal;
    margin: 0;

  }

  /* Limit the description text to 40 characters */
  .description-text2 {
    font-size: 13px;
    line-height: 1.4;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* Limit to 2 lines */
    -webkit-box-orient: vertical;
    white-space: normal;

    margin: 0;
  }



  /* Lock footer position at the bottom */
  .footer-section {
    position: absolute;
    /* Absolute positioning */
    bottom: 0;
    /* Lock to bottom */
    width: 100%;
    /* Ensure it spans full width */
    height: 60px;
    /* Adjust the height */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }

  .footer-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
  }

  .powered-by {
    font-size: 10px;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 5px;
    margin-left: 5px;
  }

  .footer-logo {
    width: 40px;
    height: 10px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
  }

  .imageDiv13 {
    width: 100%;
    /* Ensure the div takes full width */
    height: 50vh;
    /* Set height to 50% of the viewport height */
    position: relative;
    /* Ensure the container stays relative */
    overflow: hidden;
    /* Hide any overflow */
  }

  img#cimage1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .perm-btn {
    width: 270px;
    height: 30px;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    background-color: #000000;
    color: white;
    border: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-bottom: 0px;
  }

  /* Hover effect */
  /* .body-btn:hover {
      background-color: #e89d96; 
      transform: translateY(-2px);
    } */
  .perm-btn-outline {
    width: 270px;
    height: 30px;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    background-color: transparent;
    border: 1px solid black;
    color: black; /* Set text color to black */
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-bottom: 10px;
  }
}


@media only screen and (max-width: 320px) {
  .centered-container2 {
    width: 300px;
    height: 405px;
    flex-direction: column-reverse;
    text-align: center;
    padding: 0;
    position: relative;

  }

  .contentDiv2 {
    width: 100%;
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 0px;
    margin: 0;
    justify-content: space-between;
  }

  img#cimage1 {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  /* Hide the sub-heading in mobile view */
  .sub-heading {
    display: none;
  }

  /* Limit the main heading to 20 characters */
  .main-heading2 {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.2;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal;
    margin: 0;

  }

  /* Limit the description text to 40 characters */
  .description-text2 {
    font-size: 13px;
    line-height: 1.4;
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;

    margin: 0;
  }



  /* Lock footer position at the bottom */
  .footer-section {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }

  .footer-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: black;
  }

  .powered-by {
    font-size: 10px;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 5px;
    margin-left: 5px;
  }

  .footer-logo {
    width: 40px;
    height: 10px;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
  }

  .imageDiv13 {
    width: 100%;
    height: 50vh;
    position: relative;
    overflow: hidden;
  }

  img#cimage1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .perm-btn {
    width: 230px;
    height: 30px;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    background-color: #000000;
    color: white;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-bottom: 0px;
  }

  /* Hover effect */
  /* .body-btn:hover {
      background-color: #e89d96; 
      transform: translateY(-2px);
    } */
  .perm-btn-outline {
    width: 230px;
    height: 30px;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    text-align: center;
    background-color: transparent;
    border: 1px solid black;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin-bottom: 10px;
    color: black; 
  }
}