/* Full-screen overlay for the small loader */
.small-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  pointer-events: none; /* Prevent pointer interaction */
} 

/* Simplified loader spinner */
.small-loader {
  border: 4px solid rgba(255, 255, 255, 0.952); /* Outer white border */
  border-top: 4px solid #3498db; /* Blue spinner */
  width: 40px;
  height: 40px;
  border-radius: 50%; /* Ensures it's a circle */
  animation: spin 1s linear infinite;
  box-sizing: border-box; /* Prevents border from affecting the circle shape */
}

/* Keyframes for the spin animation */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Ensure the body and all elements have no unexpected borders or lines */
body, html, .container-fluid, .row, .col-12 {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
}

/* Full-screen overlay for the popup */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  border: none;
}

/* Container for the popup content */
.popup-content {
  background-color: #fff;
  padding: 30px 40px;
  border-radius: 15px;
  text-align: center;
  max-width: 450px;
  width: 90%;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

/* Styles for the header in the popup */
.popup-content h3 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  color: #333;
}

/* Styles for the paragraph in the popup */
.popup-content p {
  margin-bottom: 30px;
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

/* Styles for the button in the popup */
.popup-content button {
  padding: 12px 25px;
  border: 2px solid #007bff;
  background-color: #fff;
  color: #007bff;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 1.1rem;
}

/* Hover effect for the button */
.popup-content button:hover {
  background-color: #007bff;
  color: #fff;
}
