body {
  font-family: 'Asap', sans-serif;
}

:root {
  --containerWidth: 900px;
}

.goliveCall-container {
  width: 900px;
  /* Fixed width */
  height: 550px;
  /* Fixed height */
  background-color: white;
  border-radius: 15px;
  box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
  display: flex;

}

.column {
  flex: 1;
  padding: 10px;
}

.logo {
  width: 30px;
  height: 50px;
  margin-top: 7px;
  margin-left: 50px;
  position: absolute;
}

#subscriber {
  width: 100%;
  height: 100%;
  position: contain;
}



/* .call-screen {
  width: 100%;
  height: 100%;
  object-fit: cover; 
} */

.live-user-count {
  position: absolute;
  top: 15px;
  /* Adjust the position vertically */
  right: 5px;
  /* Adjust the position horizontally */
  color: #fff;
  /* White text color */
  font-size: 12px;
  /* Font size for the text */
  background-color: rgba(128, 128, 128, 0.5);
  /* Standard grey with 50% transparency */
  padding: 8px 16px;
  /* Padding around the text */
  border-radius: 10px;
  /* Rounded corners */
  z-index: 1000;
  /* Ensure it stays on top */
  display: flex;
  /* Align icon and text horizontally */
  align-items: center;
  /* Vertically center the icon and text */
}

.viewer-icon {
  font-size: 14px;
  /* Adjust the size of the icon */
  margin-right: 5px;
  /* Space between the icon and the number */
  color: white;
  /* Icon color */
}

.notification-toast {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}



.fullscreen .middleDiv {
  width: 100vw;
  /* Full screen width */
  height: 100vh;
  /* Full screen height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  /* Ensure it stays white */
  border-radius: 0;
  /* Remove rounded corners */
  padding: 0;
  /* Ensure no padding */
  box-sizing: border-box;
}

.goLive-btn {
  width: 200px;
  /* Keep the same width */
  height: 40px;
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  /* Center text */
  background-color: #000000;
  /* Different background color (use any color of your choice) */
  color: white;
  /* Text color */
  border: none;
  /* No border */
  transition: background-color 0.3s ease, transform 0.2s ease;
  /* Add smooth transition for hover effects */
  margin-bottom: 40px;
  /* Add margin to separate buttons */
}

.overlayy {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
}

.z_index {
  z-index: 2;
}

.video-call {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  border-radius: 15px;

}

.golive-call-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;


}


.OT_widget-poster {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  border-radius: 5px;
  z-index: 1;

}
.video-container {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  /* border-radius: 15px; */

}

.caller-info {
  width: 101px;
  height: 133px;
  background-color: transparent;
  background-image: url('/public/useravatar.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.videoNavbar {
  padding-bottom: 25px;
  width: 100%;
  z-index: 10;
}

.videoNavbar .V-navbar {
  width: 60%;
  z-index: auto;
}
/* .videoNavbar {
  padding-bottom: 25px;
  width: 100%;
}

.videoNavbar .V-navbar {
  width: 60%;
} */


.imgicon4 {
  position: absolute;
  width: 48px;
  margin-top: 415px;
  margin-left: 125px;
}

.centerDiv {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 240px;
  height: 230px;
  background-color: rgb(255, 255, 255);
  border: 2px solid black;
  border-radius: 15px;
  padding: 20px 0px;
}

.imgicon5 {
  width: 45px;
}

.ptimer {
  color: white;
  text-shadow: 0px 0px 5px black;
  font-size: larger;

}

.goLiveCall-video-section {
  /* width: 40%; */
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 15px;

  /* border-radius: 8px; */
}

.product-section {
  width: 30%;
  padding: 5px;
  overflow-y: auto;
  transition: transform 0.3s ease-in-out;
  transform: translateX(0);
}

/* Chat Section Styling */
.chat-section {
  width: 30%; /* Chat takes up 30% */
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  /* padding: 10px; */
  overflow-y: auto;
  border-left: 1px solid #ccc;
}


.product-details .close-icon {
  position: absolute;
  top: 10px;
  right: 5px;
  font-size: 1rem;
  cursor: pointer;
  z-index: 10;
}


.product-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
}

.product-item {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.product-image {
  width: 50px;
  /* Reduced image size */
  height: 50px;
  border-radius: 5px;
  margin-right: 15px;
  object-fit: cover;
}

.product-info {
  flex-grow: 1;
}

.product-title {
  font-weight: bold;
  margin: 0;
  font-size: 13px;

}

.product-subtitle {
  color: #666;
  font-size: 14px;
  margin: 5px 0;
}

.product-price {
  font-weight: bold;
  color: #000;
  font-size: 13px;

}

.product-popup {
  position: absolute; /* Position relative to the parent video section */
  bottom: 15%; /* Position the popup near the bottom of the video section */
  left: 50%; /* Center horizontally within the parent */
  transform: translateX(-50%); /* Align horizontally to the center */
  width: 90%;
  max-width: 300px;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 15px;
  display: flex;
  flex-direction: row; /* Image on the left, text on the right */
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* animation: fadeIn 0.5s ease-in-out; */
  padding: 15px;
}


.product-popup img {
  max-width: 120px;
  /* Set an appropriate size for the image */
  max-height: 120px;
  /* Maintain aspect ratio */
  border-radius: 5px;
  /* Add spacing between the image and text */
}

.product-info2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  /* Align text to the left */
  color: #020202;
  /* White text for contrast */
}

.product-title2 {
  font-size: 1rem;
  font-weight: bold;
  margin: 0;
  color: #fafafa;
  /* Optional: to make the price stand out */
}

.product-price2 {
  font-size: 1rem;
  color: #fafafa;
  /* Optional: to make the price stand out */
  margin: 5px 0 0 0;
  /* Adjust margin for price */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.product-details2 {
  display: flex;
  flex-direction: row;
  /* Image on the left, title and price on the right */
  align-items: center;
  justify-content: space-between;
  /* Distribute space between image and text */
  width: 100%;
}

.product-divider {
  width: 100%;
  height: 1px;
  background-color: #e0e0e0;
  margin: 10px 0;
}

.product-toggle {
  position: absolute;
  top: 20px;                      /* Position it towards the top of the screen */
  left: 10px;                    /* Position it on the right side */
  z-index: 10;                    /* Ensure it sits on top of other elements */
  background: #fff;               /* White background for the button */
  border-radius: 50%;             /* Circular button */
  width: 30px;                    /* Size of the button */
  height: 30px;                   /* Size of the button */
  display: flex;                  /* Use flexbox to center the icon inside */
  align-items: center;            /* Vertically center the icon */
  justify-content: center;        /* Horizontally center the icon */
  cursor: pointer;               /* Show pointer on hover */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add subtle shadow for better visibility */
  /* transition: width 0.3s; */
}

.product-toggle svg {
  width: 18px;                    /* Size of the FontAwesome icon */
  height: 18px;                   /* Size of the FontAwesome icon */
}

/* Adjustments when fullscreen is active */
body.fullscreen .product-toggle {
  top: 20px;                      /* Keep top position same */
  right: 20px;                    /* Move the button a bit further to the right */
  z-index: 9999;                  /* Ensure it's above all other elements in fullscreen */
}

.product-section.hidden {
  transform: translateX(-100%);
}

.product-section.hidden+.product-toggle {
  left: 100px;
}


.live-status-icon {
  width: 20px;
  margin-right: 10px;
}

/* .duration {
  font-weight: bold;
  color: rgb(0, 0, 0);
  font-size: 14px;
  text-shadow: 0px 0px 5px rgba(212, 204, 204, 0.7);
} */

.add-to-cart-icon {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  /* padding: 8px; */
  /* background-color: #010703;  */
  color: rgb(10, 10, 10);
  /* border-radius: 5px; */
}

.popup-notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ffffff;
  /* White background */
  color: #333;
  /* Dark text for contrast */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  z-index: 1000;
  opacity: 0.95;
  animation: fadeIn 0.3s ease-in-out;
  max-width: 250px;
  text-align: center;
}

.popup-icon {
  color: #4caf50;
  /* Green for success */
  font-size: 40px;
  /* Larger icon */
}

.popup-message {
  font-size: 16px;
  line-height: 1.4;
  color: #333;
  /* Dark color for readability */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 0.95;
    transform: translateY(0);
  }
}

.quantity-control {
  display: flex;
  align-items: center;
  gap: 5px;
  padding-right: 10%;
}

.quantity-number {
  font-weight: bold;
  font-size: 16px;
  margin: 0 5px;
}

.add-to-cart-btn,
.back-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
}

.add-to-cart-btn {
  background-color: #28a745;
  color: #fff;
}

.back-btn {
  background-color: #6c757d;
  color: #fff;
  margin-top: 10px;
}

.product-image-large {
  width: 80%;
  max-width: 250px;
  border-radius: 8px;
  object-fit: cover;
}

.product-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 10px;
}

.product-price {
  color: #666;
  margin: 5px 0;
}

.details-btn {
  padding: 5px 10px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  margin-top: 8px;
}

.product-item:hover {
  background-color: #f8f8f8;
}

.product-thumbnail {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  margin-right: 15px;
  object-fit: cover;
}

.checkout-btn {
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4CAF50;
  /* A fresh green shade */
  color: white;
  border: none;
  border-radius: 20px;
  /* Rounded button style */
  padding: 6px 14px;
  /* Reduced padding for a compact look */
  font-size: 14px;
  /* Slightly smaller font size */
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.checkout-btn:hover {
  background-color: #43A047;
  /* Darker green on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  /* Add subtle shadow on hover */
}

.checkout-btn .fa-shopping-cart {
  font-size: 16px;
  margin-right: 6px;
  /* Adjust icon spacing */
}

.liveLogoDiv {
  padding-left: 10%;
  padding-right: 10%;
}
.livestatusimg {
  margin-left: 10px;
  padding-right: 10%;
}

.livestatusimg2 {
color: #000;
}
/* Style for the Like button */
.like-button {
  display: inline-block;
  cursor: pointer;
  font-size: 3rem; /* Font size for the heart icon */
  transition: transform 0.3s ease;
}

.like-button:hover {
  transform: scale(1.2); /* Slightly enlarge on hover */
}

/* GoLiveMain.css */

.animate-like {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: heartAnimation 1s ease-in-out forwards;
}

@keyframes heartAnimation {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    opacity: 0.8;
    transform: translate(-50%, -60%) scale(1.5);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -70%) scale(0);
  }
}

.right-icons {
  position: absolute;
  right: 8px; /* Distance from the right edge */
  top: 50%; /* Vertically center */
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.icon-style {
  width: 35px;
  height: 35px;
  cursor: pointer;
}
.share-toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.share-container {
  position: relative;
}

.share-popup {
  position: absolute;
  top: -80px;
  right: 0;
  background-color:#fff;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  z-index: 999;
}

.share-icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

/* Video Blur Overlay */
.video-overlay {
  position: absolute;
  top: 0; /* Aligns perfectly with the top edge */
  left: 0;
  width: 100%;
  height: 15%; /* Height of the blur area */
  pointer-events: none; /* Allows interaction with elements below */
  z-index: 1; /* Ensures it’s above the video */
  overflow: hidden; /* No bleed at the edges */
  border-radius: 5px;

}

.blur-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  filter: blur(0.5px); /* Slight blur */
  transform: scale(1.02); /* Fix edge bleed by scaling */
  pointer-events: none;

}


.liveLogoContainer {
  position: absolute;
  top: 12px;
  left: 10px; /* Position at top-left corner */
  display: flex;
  align-items: center;
  gap: 8px; /* Space between image and text */
  z-index: 10;
}

.top-left-image {
  width: 50px; /* Adjust width as needed */
  height: 50px;
  border-radius: 50%; /* Makes the image circular */
  object-fit: cover;
}

/* Container for top-left logo, title, and live badge */




/* Title and LIVE badge container */
.live-title {
  display: flex;
  flex-direction: column;
  color: white;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.3;
  font-size: 16px;
}

/* LIVE Badge styling */
.live-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ff0000; /* Red background */
  color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px; /* Rounded corners */
  padding: 2px 8px;
  margin-top: 5px; /* Spacing below the title */
  max-width: fit-content;
  gap: 5px; /* Space between text and circle */
}

.live-text {
  text-transform: uppercase;
  font-size: 10px;
  letter-spacing: 1px;
}

.live-circle {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
}

.spinner {
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #000; /* Black */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.heart-container {
  position: relative;
}

.icon-style:active {
  transform: scale(1.2); /* Pop effect when clicked */
  transition: transform 0.2s ease-in-out;

}

.floating-heart {
  position: absolute;
  bottom: 0;
  font-size: 1.5rem; /* Base heart size */
  color: red; /* Heart color */
  animation: floatUp 2s ease-out forwards;
  opacity: 1;
  pointer-events: none; /* Prevent interaction */
  z-index: 999;
}

@keyframes floatUp {
  0% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
  30% {
    transform: translateY(-80px) scale(1.5);
    opacity: 0.9;
  }
  70% {
    transform: translateY(-200px) scale(1.2);
    opacity: 0.6;
  }
  100% {
    opacity: 0;
    transform: translateY(-300px) scale(0.8);
  }
}
/* Chat overlay positioning */
.golive-chat-overlay {
  position: absolute;
  bottom: 10px; /* Distance from bottom */
  left: 10px; /* Adjust position */
  right: 10px; /* Stretch width */
  pointer-events: none; /* Allow video to remain interactive */
}

/* Chat input still interactive */
.inline-chat-input {
  pointer-events: auto; /* Allow input field to accept interaction */
}



@media screen and (max-width: 915px) {
  :root {
    --containerWidth: 800px;
  }
}

@media screen and (max-width: 815px) {
  :root {
    --containerWidth: 760px;
  }
}

.CallbodyContainer .row {
  height: 100% !important;
}

.labelOnscreen {
  display: none;
}

.button-container {
  display: flex;                /* Use flexbox to arrange buttons horizontally */
  justify-content: center;      /* Center the buttons horizontally */
  align-items: center;          /* Align the buttons vertically in the center */
  gap: 70px;                    /* Space between the buttons */
  position: relative;           /* To position buttons correctly in their parent container */
  z-index: 1;
}

.button-container div {
  cursor: pointer;             /* Make sure the divs with the images are clickable */
  transition: transform 0.3s ease; /* Smooth transform transition for hover effect */
}

.button-container div:hover {
  transform: scale(1.1);        /* Slightly enlarge the button on hover */
}

.button-container img {
  width: 100px;                 /* Width for the button images */
  height: 45px;                 /* Height for the button images */
  object-fit: contain;          /* Make sure the images maintain aspect ratio */
  display: block;               /* Ensure images are block elements for better layout control */
}



/* Media Query for Mobile View */
@media (max-width: 768px) {

  /* Main Container for Mobile */
  /* Container for the main live call */
  .goliveCall-container {
    display: flex;
    flex-direction: column;
    /* Ensure proper stacking for mobile */
    height: 85vh;
    /* Full viewport height */
    width: 95vw;
    /* Full viewport width */
    overflow: hidden;
    /* Prevent any content from overflowing */
    margin: 0;
    /* Remove any default margin */
    padding: 0;
    /* Remove padding */
    /* position: relative; */
    /* Position relative for absolute child elements */
    box-sizing: border-box;
    /* Ensure padding and borders are included in width/height */
    /* border-radius: 15px; */

    
  }


  .liveLogoDiv .livestatusimg {
    /* pointer-events: auto; */
    position: absolute;
    top: 5px;
    /* Position it at the top */
    left: 15px;
    /* Align it to the left */
    width: 500px;
    /* Set an appropriate width */
    height: auto;
    /* Maintain aspect ratio */
    z-index: 10;
    /* Ensure it appears above other elements */
    border-radius: 50%;
    /* Optional: Creat  e a circular icon if the image is square */
    padding: 5px;
    /* Add some padding to separate the image from the edges */
  }

  /* Video section styling */
  .goLiveCall-video-section {
    /* flex: 1; */
    /* Take up remaining space */
    width: 100%;
    /* Ensure it spans full width */
    height: 100%;
    /* Match container height */
    display: flex;
    justify-content: center;
    /* Center content horizontally */
    align-items: center;
    /* Center content vertically */
    position: relative;
    /* Position relative to allow absolutely positioned children */
    overflow: hidden;
    /* Prevent any overflow */
    background-color: #000;
    /* Optional: Add a fallback background color */
  }

  /* Overlay for the video section */
  /* .overlayy {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    pointer-events: none;
  } */



  /* Video control buttons */
  /* .videoNavbar {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    z-index: 1000px;
  } */

  /* Product section that slides up and down in mobile view */
  .product-section-mobile {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    max-height: 30vh;
    background-color: #fff;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease;
    overflow-y: auto;
    z-index: 1000;
  }



  .product-section-mobile.hidden {
    transform: translateY(100%) scale(0.95); /* Slide down and slightly scaled */
    opacity: 0; /* Fade out */
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15); /* Reset shadow */
  }
  
  .product-section-mobile.visible {
    transform: translateY(0) scale(1); /* Slide up and scale to normal size */
    opacity: 1; /* Fully visible */
    box-shadow: 0 -4px 20px rgba(0, 0, 0, 0.25); /* Add a stronger shadow for depth */
  }
  
  /* Optional: Add a fade-in animation for child elements */
  .product-section-mobile .product-item {
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Keyframe for fading in child elements */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  /* Product list item styling */
  .product-item {
    padding: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e0e0e0;
  }

  .product-image {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
  }

  .product-info {
    flex: 1;
  }

  .product-title {
    font-weight: bold;
    font-size: 1rem;
    color: #070707;
  }

  .product-price {
    font-size: 0.9rem;
    color: #080808;
  }

  .add-to-cart-icon {
    cursor: pointer;
  }

  .add-to-cart-btn {
    background-color: #000;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  /* Mobile product toggle button fixed at the bottom right */
  .mobile-product-toggle {
    position: fixed;
    top: 55px;
    right: 20px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 101;
    cursor: pointer;
    border: 1px solid transparent;
    text-align: center;
    background-color: transparent;
    /* Remove the white background */

  }

  .Product-icon {
    width: 25px;
    /* Ensure the width is fixed */
    z-index: "1000px";
  }

  .mobile-product-toggle svg {
    margin-right: 4px;
  }

  .mobile-product-toggle span {
    display: block;
    text-align: center;
    font-size: 0.75rem;
    margin-top: 4px;
  }

  .videoNavbar {
    /* position: absolute; */
    /* bottom: 20px; */
    /* transform: translateX(-37%); */
    /* display: flex; */
    /* gap: 20px; */
  }

  .button-container {
    display: flex;
    gap: 20px;
    pointer-events: auto;
    z-index: 1;

  }

  .button-container img {
    width: 60px;
    /* Adjust button size for better fit on mobile */
    height: 60px;
    cursor: pointer;
  }

  .product-popup {
    position: absolute; /* Position relative to the parent video section */
    bottom: 10%; /* Position the popup near the bottom of the video section */
    left: 50%; /* Center horizontally within the parent */
    transform: translateX(-50%); /* Align horizontally to the center */
    width: 90%;
    max-width: 300px;
    height: 80px;
    background-color: rgba(0, 0, 0, 0.9);
    border-radius: 15px;
    display: flex;
    flex-direction: row; /* Image on the left, text on the right */
    align-items: center;
    justify-content: center;
    z-index: 1000;
    /* animation: fadeIn 0.5s ease-in-out; */
    padding: 15px;
  }

  .product-details2 {
    display: flex;
    flex-direction: row;
    /* Image on the left, title and price on the right */
    align-items: center;
    justify-content: space-between;
    /* Distribute space between image and text */
    width: 100%;
  }

  .product-image {
    max-width: 40%;
    /* Limit the image width to 40% of the container */
    height: auto;
    border-radius: 10px;
    /* Optional: rounded corners */
    margin-right: 10px;
    /* Space between the image and text */
    object-fit: cover;
  }

  /* Optional fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .product-title2 {
    font-weight: bold;
    font-size: 1rem;
    color: #ffffff;
    padding-top: 10px;
  }

  .product-price2 {
    font-size: 0.9rem;
    color: #ffffff;
  }

  .live-user-count {

    right: 15px;
    /* Adjust the position horizontally */

  }
  .right-icons {
    position: absolute;
    right: 15px; /* Distance from the right edge */
    top: 35%; /* Vertically center */
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .icon-style {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }
  
.golive-call-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


}