body {
  font-family: 'Asap', sans-serif;
}

:root {
  --containerWidth: 900px;
}

.column {
  flex: 1;
  padding: 10px;
}

.logo {
  width: 30px;
  height: 50px;
  margin-top: 7px;
  margin-left: 50px;
  position: absolute;
}


/* When the fullscreen class is applied, the white container fills the screen */
.fullscreen .middleDiv {
  width: 100vw;  /* Full screen width */
  height: 100vh; /* Full screen height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;  /* Ensure it stays white */
  border-radius: 0;  /* Remove rounded corners */
  padding: 0;  /* Ensure no padding */
  box-sizing: border-box;
}


.para {
  margin-top: 16px;
  margin-left: -20px;
  font-size: 16px;
  font-family: 'Asap', sans-serif;
  position: absolute;
}

.logo1 {
  width: 30px;
  margin-top: -2px;
  margin-left: 193px;
  position: absolute;
}

.para1 {
  margin-top: 17px;
  margin-left: 100px;
  font-weight: 400;
  font-family: 'Asap', sans-serif;
  font-size: 16px;
}

.para2 {
  margin-top: 490px;
  margin-left: 205px;
}

.paralive {
  color: chocolate;
}

.overlayy {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
}

.z_index {
  z-index: 2;
}

.video-call {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  /* background-image: url('/public/salesmanavatar.png'); */
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center;
}

.OT_widget-container {
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
}

.caller-info {
  width: 101px;
  height: 133px;
  background-color: transparent;
  background-image: url('/public/useravatar.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.logolast {
  margin-top: 440px;
  margin-left: -26px;
  position: absolute;
  width: 135px;
}

.videoNavbar {
  padding-bottom: 25px;
  width: 100%;
}

.videoNavbar .V-navbar {
  width: 60%;
}

.callerDiv {
  width: 80%;
  padding-bottom: 20px;
}

.imgicon3 {
  position: absolute;
  width: 48px;
  margin-top: 415px;
  margin-left: 30px;
}

.imgicon4 {
  position: absolute;
  width: 48px;
  margin-top: 415px;
  margin-left: 125px;
}

.centerDiv {
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 240px;
  height: 230px;
  background-color: rgb(255, 255, 255);
  border: 2px solid black;
  border-radius: 20px;
  padding: 20px 0px;
}

.imgicon5 {
  width: 45px;
}

.imgicon6 {
  width: 60px;
}

.end1 {
  font-weight: 600;
  font-family: 'Asap', sans-serif;
  white-space: nowrap;
  font-size: 17px;
}

.imgicon7 {
  width: 110px;
  height: 50px;
}

.button_body h3 {
  margin-top: -35px;
}

.button_body2 h3 {
  margin-top: -23px;
}

.end2 {
  font-weight: 600;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  white-space: nowrap;
  font-size: 16px;
  color: #fafafa;
  font-family: 'Asap', sans-serif;
}

.imgicon8 {
  width: 110px;
}

.end3 {
  font-family: 'Asap', sans-serif;
  font-weight: 600;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  white-space: nowrap;
  font-size: 16px;
  color: #000000;
}

.additionalIcon1 {
  position: absolute;
  width: 150px;
  height: 50px;
  margin-top: 162px;
  margin-left: 65px;
}

.footerlogo {
  display: none;
}

.ptimer {
  color: white;
  text-shadow: 0px 0px 5px black;
  font-size: larger;
}

@media screen and (max-width: 915px) {
  :root {
    --containerWidth: 800px;
  }
}

@media screen and (max-width: 815px) {
  :root {
    --containerWidth: 760px;
  }
}

.CallbodyContainer .row {
  height: 100% !important;
}

.labelOnscreen {
  display: none;
}

@media screen and (max-width: 768px) {
  .callDurationDiv {
    position: absolute;
    right: 10px; /* Adjusted right positioning */
    top: 60px;   /* Adjusted top positioning */
    padding: 3px 7px;  /* Smaller padding */
    border-radius: 8px; /* More rounded corners */
    color: rgb(0, 0, 0);
    font-size: 14px; /* Slightly smaller font size */
    font-weight: bold;
    text-shadow: 0px 0px 5px rgba(212, 204, 204, 0.7); /* Added text shadow for visibility */
  }


  .liveLogoDiv {  
    display: flex;
    flex-direction: column;
  }

  .liveLogofurniture {
    display: block !important;
    width: 120px !important;
  }

  .livestatusimg {
    margin-top: 10px;
  }

  .footerlogo {
    width: 100px;
  }

  .video-call {
    border-radius: 15px 15px 0px 0px !important;
    overflow: hidden !important;
  }

  .middleDiv {
    padding-right: 0px !important;
    padding-left: 0px !important;
    height: 88dvh !important;
  }

  .callbodyRow .middleDiv {
    border-radius: 10px 0px 0px 0px !important;
  }

  .upperDiv {
    display: none;
  }

  .coprightLogotop {
    display: none;
  }

  .lowerDiv {
    height: 7dvh !important;
  }

  .callbodyRow {
    padding-bottom: 20px;
  }

  .livebtn {
    align-items: flex-start;
  }

  .footerlogoSm {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .labelOnscreen p {
    color: #000;
  }

  .labelOnscreen {
    display: flex;
    justify-content: center;
    align-items: start;
  }

  .labelDiv {
    display: none !important;
  }

  .fullwidthSm {
    width: 100% !important;
  }

  .footerlogo {
    display: flex !important;
    width: 22%;
  }

  .CallbodyContainer .row {
    height: -webkit-fill-available !important;
    display: block;
  }

  :root {
    --containerWidth: 98dvw;
  }

  .CallbodyContainer {
    height: 95dvh !important;
  }
}

@media screen and (max-width: 500px) {
  .videoNavbar .V-navbar {
    width: 65%;
  }
}

@media screen and (max-width: 450px) {}

.V-navbar img {
  width: 40px !important;
}

.connectivity {
  background-color: #111F3D;
  color: #fff;
  padding: 30px;
  display: flex;
}

@media screen and (max-width: 430px) {
  .videoNavbar .V-navbar {
    width: 90%;
  }
}

@media screen and (max-width: 400px) {
  .videoNavbar .V-navbar {
    width: 85%;
  }
}

@media screen and (max-width: 370px) {
  .videoNavbar .V-navbar {
    width: 80%;
  }
}

@media screen and (max-width: 350px) {
  .videoNavbar .V-navbar {
    width: 85%;
  }
}

@media screen and (max-width: 330px) {
  .videoNavbar .V-navbar {
    width: 90%;
  }

  .caller-info {
    width: 70px;
    height: 80px;
    background-color: #000000;
    background-image: url('/public/useravatar.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.additionalIcon2 {
  position: absolute;
  margin-left: 200px;
  margin-top: 200px;
}

.CallbodyContainer {
  width: var(--containerWidth) !important;
  height: 550px;
}

.labelDiv {
  width: 100%;
  height: 100%;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
}

.label-img {
  height: auto;
  width: 40px;
  padding-left: 10px;
}

.logoDiv {
  width: 100%;
  height: 100%;
  padding: 20px 10px;
}

.livebtn {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.liveLogofurniture {
  display: none;
}

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  .callbodyRow .lowerDiv {}
}

.ringing-message {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'SF Pro', sans-serif;
  font-size: 18px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.ringing-dots {
  display: inline-block;
  margin-left: 5px;
  width: 20px;
  text-align: left;
  content: ' ';
  animation: dot-blink 1.5s steps(1, end) infinite;
}

@keyframes dot-blink {
  0%, 20% {
    content: ' ';
  }
  40% {
    content: '.';
  }
  60% {
    content: '..';
  }
  80%, 100% {
    content: '...';
  }
}

.ringing-dots::after {
  content: ' ';
  animation: dot-blink 1.5s steps(1, end) infinite;
}

