.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  border-radius: 20px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%
  ; /* Allow full width on mobile */
}

.popup-header {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.popup-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.popup-image {
  width: 50%; /* Larger on mobile for better visibility */
}

.popup-text {
  font-size: 14px;
  text-align: center;
  color: #000000;
}

.popup-text ol {
  margin-top: 10px;
  text-align: left; /* Centered text for instructions */
}

.popup-text li {
  font-size: 14 px; /* Larger font size for readability */
}

@media (min-width: 768px) {
  .popup-content {
    padding: 35px;
    max-width: 550px; /* Fixed max width for desktop */
  }

  .popup-image {
    width: 180px; /* Fixed size for desktop */
  }

  .popup-header {
    font-size: 16px;
  }

  .popup-body {
    flex-direction: row; /* Side by side layout on desktop */
    align-items: flex-start; /* Align to the top */
    gap: 20px; /* Space between elements */
  }

  .popup-text {
    text-align: left; /* Left-aligned text for desktop */
  }

  .popup-text ol {
    text-align: left;
  }

  .popup-text li {
    font-size: 12px; /* Smaller font for desktop */
  }
}
