.golive-container {
    width: 700px; /* Fixed width */
    height: 550px; /* Fixed height */
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 8px 25px rgba(0, 0, 0, 0.15);
    display: flex;
     /* Keep space between content and image */
    position: relative;
    overflow: hidden;
  }
  
  .content-section {
    width: 40%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; /* Align items to center for buttons */
    height: 100%;
  }
  
  .golive-sub-text {
    font-size: 17px;
    font-weight: bold;
    line-height: 1.4;
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-align: center;
  }
  
  .golive-paragraph-text {
    font-size: 15px;
    line-height: 1.6;
    max-height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: normal;

  }
  .golive-main-heading {
    font-size: 20px;
    font-weight: bold;
    text-align: center; /* Center align the text */
    max-height: 100%;
  }
  
  .golive-countdown {
    font-size: 40px; /* Larger font size for countdown */
    font-weight: bold;
   
    text-align: center; /* Center countdown text */
    /* Add space between countdown and button */
  }
  
  .goLive-btn {
    width: 200px; /* Keep the same width */
    height: 40px;
    padding: 5px 20px;
    border-radius: 5px;
    font-size: 12px;
    cursor: pointer;
    text-align: center; /* Center text */
    background-color: #000000; /* Different background color (use any color of your choice) */
    color: white; /* Text color */
    border: none; /* No border */
    transition: background-color 0.3s ease, transform 0.2s ease; /* Add smooth transition for hover effects */
    margin-bottom: 40px; /* Add margin to separate buttons */
  }
  
  .image-section {
    width: 60%; /* Adjusted width to 60% for the image */
    height: 100%; /* Ensure the div covers the full height */
    display: flex; /* Ensure the image takes full height */
  }
  
  .golive-image {
    width: 100%; /* Ensure the image takes up the full width */
    height: 100%; /* Ensure the image takes up the full height */
    object-fit: cover; /* Ensure the image covers the area without distorting */
  }
  
  .golive-footer {
    position: absolute;
    bottom: 0;
    width: 100%; /* Ensures full width */
    display: flex;
    align-items: center;
    justify-content: center; /* Centers content */
    height: 30px; /* Increased height to allow more space */
    background-color: transparent; /* Optional if you want to adjust background color */
}

.footer-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: black;
}

.powered-by {
    font-size: 10px; /* Slightly larger font size */
    margin-right: 5px;
    margin-left: 5px;

}

.footer-logo {
    width: 35px; /* Slightly increased logo size */
    height: auto;
    
}

/* Media Query for Mobile (max-width: 768px) */
@media only screen and (max-width: 768px) {
  .golive-container {
    width: 390px;
    height: auto; /* Allow height to adjust based on content */
    flex-direction: column-reverse;
    max-height: 500px;
    
    
  }

  .content-section {
    width: 100%;
    padding: 10px;
    justify-content: flex-start;
  }

  .golive-main-heading {
    font-size: 20px;
    max-height: 100%;
    overflow: hidden;
    line-height: 1.4;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* white-space: nowrap; */
  }

  .golive-sub-text {
    font-size: 14px;
    max-height: 100%;
    -webkit-line-clamp: 3;
    margin-top: 8px;
    line-height: 1.4;
  }

  .golive-paragraph-text {
    font-size: 12px;
    line-height: 1.4;
    max-height: 100%;
    -webkit-line-clamp: 4;
    max-height: 100%;
    
  }

  .golive-countdown {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .goLive-btn {
    width: 150px;
    height: 35px;
    font-size: 12px;
    margin-top: 15px;
  }

  .image-section {
    width: 100%;
    height: auto;
  }

  .golive-image {
    height: 100%;
    object-fit: contain;
  }
}

/* Media Query for Small Mobile (max-width: 375px) */
@media only screen and (max-width: 390px) {
  .golive-container {
    width: 350px;
    height: auto;
    flex-direction: column-reverse;
  }

  .content-section {
    width: 100%;
    padding: 10px;
  }

  .golive-main-heading {
    font-size: 20px;
    max-height: 100%;
    -webkit-line-clamp: 2;

  }

  .golive-sub-text {
    font-size: 12px;
    max-height: 100%;
  }

  .golive-paragraph-text {
    font-size: 12px;
    max-height: 100%;
  }

  .golive-countdown {
    font-size: 28px;
  }

  .goLive-btn {
    width: 130px;
    height: 30px;
    font-size: 11px;
  }

  .image-section {
    width: 100%;
    height: auto;
  }
}

/* Media Query for Extra Small Mobile (max-width: 320px) */
@media only screen and (max-width: 320px) {
  .golive-container {
    width: 310px;
    height: auto;
    flex-direction: column-reverse;
  }

  .content-section {
    width: 100%;
    padding: 5px;
  }

  .golive-main-heading {
    font-size: 18px;
    max-height: 100%;
    -webkit-line-clamp: 2;

  }

  .golive-sub-text {
    font-size: 10px;
  }

  .golive-paragraph-text {
    font-size: 12px;
    max-height: 100%;
    -webkit-line-clamp: 3;
    margin-top: 5px;
    line-height: 1.4;
  }

  .golive-countdown {
    font-size: 24px;
  }

  .goLive-btn {
    width: 120px;
    height: 30px;
    font-size: 10px;
  }

  .image-section {
    width: 100%;
    height: auto;
  }
}