/* Container Styling */
.decision-container {
    width: 450px;
    height: 500px;
    background-color:transparent;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
  }
  
  /* Heading Styling */
  .decision-heading {
    font-size: 28px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
  
  .decision-divider {
    width: 100%;
    border: 0.5px solid #000000;
   
  }
  
  /* Options Container Styling */
  .decision-options {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
  
  .decision-option {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    width: 100%;
    padding: 10px 0;
    /* border-bottom: 1px solid #eee; */
  }
  
  /* Image Styling */
  .image-container {
    flex: 0.4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .decision-image {
    width: 125px;
    height: 125px;
    /* object-fit: cover; */
    clip-path: circle(35% at 50% 50%); /* Creates a circular shape */
  }
  
  /* Text Container Styling */
  .text-container {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .decision-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
  }
  
  .decision-description {
    font-size: 14px;
    color: #666;
  }
  
  /* Button Styling */
  .decision-btn {
    padding: 8px 12px;
    font-size: 14px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    width: fit-content;
    
  }
  
  .one-to-one {
    background-color: grey; /* Greyed out since it's disabled */
    color: #fff;
  }
  
  .go-live {
    background-color: grey; /* Greyed out since it's disabled */
    color: #fff;
  }
  
  .decision-btn:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  /* Footer Styling */
  .decision-footer {
    font-size: 12px;
    color: #333;
    text-align: center;
    margin-top: 15px;
  }
  
  .decision-brand {
    font-weight: bold;
    color: #007bff;
  }
  
  @media screen and (max-width: 765px) {
    .decision-container {
        width: 90vw; /* Full width with padding */
        height: 55vh;
        padding: 15px; /* Reduced padding */
    }

    .decision-heading {
        font-size: 18px; /* Smaller font for heading */
    }

    .decision-image {
        width: 100px; /* Smaller circular image */
        height: 100px;
    }

    .decision-title {
        font-size: 14px; /* Slightly smaller title */
    }

    .decision-description {
        font-size: 12px; /* Adjust description font size */
    }

    .decision-btn {
        padding: 6px 10px; /* Smaller button size */
        font-size: 12px; /* Adjust button text size */
    }

    .decision-footer {
        font-size: 10px; /* Smaller footer font size */
    }
  }

  